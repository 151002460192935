import React, { useEffect, useState, useRef } from "react";
import { apiGetTournamentsByUser, apiGetTournamentsByList, apiGetUserTournament } from "../../services/service";

const Modal = ({ id, onClose }) => {
  const [expandedIndex, setExpandedIndex] = useState(null); 
  const [tournaments, setTournaments] = useState([]);
  const userId = id;


  const modalTournamentList = async (userId) => {
    try {
     const response = await apiGetUserTournament({ userId });

      if (response.status === 1) {
        console.log(response)
        console.log(userId)
        console.log("kkk")
        setTournaments(response.data);
      }
    } catch (error) {
      console.error("Something went wrong", error);
    } 
  };

  useEffect(()=>{
    modalTournamentList(userId);
  }, [])

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  if (!onClose) return null; // Add conditional logic after hooks

  return (
    <>
      <div className="help-modal-container" onClick={onClose}>
        <div
          className="help-modal-box tournament-modal-box"
          onClick={(e) => e.stopPropagation()}
        >
          <section>
            <p>Tournament Lists</p>
            {tournaments.map((tournament, index) => (
              <div
                key={tournament.id}
                className="tournament-list-box"
                onClick={() => toggleAccordion(index)}
              >
                <div className="tournament-card">
                  <div className="tournament-details">
                    <div className="tournament-flex">
                      <p><strong>Date: </strong>
                      {new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          timeZone: "Asia/Kolkata",
                        }).format(new Date(tournament.creation_date))}
                      </p>
                      <p><strong>ID:</strong> {tournament.id}</p>
                    </div>
                    <h3 className="tournament-name">
                        {tournament.name}
                    </h3>
                    {expandedIndex === index && (
                      <>
                        <p className="tournament-url"><strong>Url: </strong><a href={tournament.url}>{tournament.url}</a></p>
                        <div className="tournament-flex">
                          <p><strong>Photo Uploaded:</strong> {tournament.photos ? 'Yes' : 'No'}</p>
                          <p><strong>Phone:</strong> {tournament.phone}</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </section>
        </div>
        <div className="help-cross tournament-cross" onClick={onClose}>
          X
        </div>
      </div>
    </>
  );
};


const Tournaments = () => {
  const [activeTab, setActiveTab] = useState("user");
  const [tournamentUsers, setTournamentUsers] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [filterType, setFilterType] = useState("");
  const [userId, setUserId] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);



  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(80); // Assuming totalPages is provided by response.pagination

  const isRequestInProgress = useRef(false);

  const handleRowClick = (rowData) => {
    setUserId(rowData.id)
    setIsModalOpen(true);
  };

  const getPaginationNumbers = (totalPages, currentPage) => {
    const visiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage + 1 < visiblePages) {
      startPage = Math.max(1, endPage - visiblePages + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };

  const handlePageChange = (page) => {
    if (page !== currentPage && !isLoading) {
      setCurrentPage(page);
    }
  };

  const handlePageChange2 = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages2) {
      setCurrentPage2(pageNumber);
      getTournamentsByList(); // Fetch tournaments for the selected page
    }
  };

  const getTournamentsByUser = async (page) => {
    if (isRequestInProgress.current) return;

    try {
      isRequestInProgress.current = true;
      setIsLoading(true);
      const response = await apiGetTournamentsByUser({ page, limit });
      if (response.status === 1) {
        const filteredUsers = filterType
          ? response.data.filter((user) => {
            if (filterType === "coach") return user.type_id === 1;
            if (filterType === "academy") return user.type_id === 2;
            if (filterType === "tournament") return user.type_id === 5;
            return true;
          })
          : response.data;

        setTournamentUsers(filteredUsers);
        setTotalPages(response.pagination.totalPages);
        setCurrentPage(response.pagination.currentPage);
      }
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setIsLoading(false);
      isRequestInProgress.current = false;
    }
  };



  const getTournamentsByList = async () => {
    if (isRequestInProgress.current) return;
  
    try {
      isRequestInProgress.current = true;
      setIsLoading(true);
  
      const response = await apiGetTournamentsByList({ page: currentPage2, limit });
  
      if (response.status === 1) {

        const sortedTournaments = response.data.sort((a, b) => {
          const dateA = new Date(a.creationDate); // Adjust if necessary based on the field name.
          const dateB = new Date(b.creationDate);
          return dateB - dateA; // Sorting in descending order (latest first)
        });
  
        setTournaments(sortedTournaments);
        setTotalPages2(response.pagination.totalPages);
      }
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setIsLoading(false);
      isRequestInProgress.current = false;
    }
  };
  

  useEffect(() => {
    if (activeTab === "user") {
      getTournamentsByUser(currentPage);
    } else if (activeTab === "tournaments") {
      getTournamentsByList();
    }
  }, [activeTab, currentPage, currentPage2, limit, filterType]);



  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLimitChange = (event) => {
    setLimit(Number(event.target.value));
    setCurrentPage(1);
    setCurrentPage2(1);
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  return (
    <>
      <div
        className="genral-setting-btn genral-setting-fonts"
        style={{ marginTop: "1rem" }}
      >
        <button
          className={`genral-btn ${activeTab === "user" ? "genral-active" : ""}`}
          onClick={() => handleTabClick("user")}
        >
          User Registered
        </button>
        <button
          className={`genral-btn ${activeTab === "tournaments" ? "genral-active" : ""}`}
          onClick={() => handleTabClick("tournaments")}
        >
          View Tournaments
        </button>
      </div>

      {activeTab === "user" && (
        <div>
        <div className="dropdown-flex">
          <div className="pagination-limit">
            <label htmlFor="limit" className="limit-label">
              Items per page:
            </label>
            <select
              id="limit"
              value={limit}
              onChange={handleLimitChange}
              className="limit-select"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="pagination-limit">
            <label htmlFor="filter" className="limit-label">
              Filter by Type:
            </label>
            <select
              id="filter"
              value={filterType}
              onChange={handleFilterChange}
              className="limit-select"
            >
              <option value="">All</option>
              <option value="coach">Coach</option>
              <option value="academy">Academy</option>
              <option value="tournament">Tournament</option>
            </select>
          </div>
          </div>
          <div
            className="import-tab-table"
            style={{ height: "400px", overflow: "auto" }}
          >
            <table>
              <thead>
                <tr>
                  <th className="common-fonts">S NO</th>
                  <th className="common-fonts">User Id</th>
                  <th className="common-fonts">DATE</th>
                  <th className="common-fonts">Name</th>
                  <th className="common-fonts">Phone</th>
                  <th className="common-fonts">Type</th>
                  <th className="common-fonts">Tournament listed</th>
                </tr>
              </thead>
              <tbody>
                {tournamentUsers.length === 0 ? (
                  <tr>
                    <td colSpan={10} style={{ textAlign: "center" }}>
                      {isLoading ? "Loading..." : "No data found"}
                    </td>
                  </tr>
                ) : (
                  tournamentUsers.map((user, index) => (
                    <tr key={user.id} onClick={() => handleRowClick(user)}>
                      <td className="common-fonts">
                        {index + 1 + (currentPage - 1) * limit}
                      </td>
                      <td className="common-fonts">{user.id}</td>
                      <td className="common-fonts">
                        {new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          timeZone: "Asia/Kolkata",
                        }).format(new Date(user.creation_date))}
                      </td>
                      <td className="common-fonts">{user.name}</td>
                      <td className="common-fonts">{user.phone}</td>
                      <td className="common-fonts">
                        {user.type_id === 1
                          ? "COACH"
                          : user.type_id === 2
                            ? "ACADEMY"
                            : "TOURNAMENT"}
                      </td>
                      <td className="common-fonts">
                        {user.is_tournament_added === 0 ? "NO" : "YES"}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="tour-pagination">
            <button
              className={`tour-arrow-btn ${currentPage > 1 ? "" : "tour-hidden-arrow"
                }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              &#60;
            </button>
            <ul className="tour-pagination-flex">
              {getPaginationNumbers(totalPages, currentPage).map((page) => (
                <li key={page}>
                  <button
                    className={`tour-page-btn ${currentPage === page ? "active" : ""
                      }`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}
            </ul>
            <button
              className={`tour-arrow-btn ${currentPage < totalPages ? "" : "tour-hidden-arrow"
                }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              &#62;
            </button>
          </div>
        </div>
      )}

      {activeTab === "tournaments" && (
        <div>
          <div className="pagination-limit">
            <label htmlFor="limit" className="limit-label">
              Items per page:
            </label>
            <select
              id="limit"
              value={limit}
              onChange={handleLimitChange}
              className="limit-select"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>

          <div
            className="import-tab-table"
            style={{ height: "400px", overflow: "auto" }}
          >
            <table>
              <thead>
                <tr>
                  <th className="common-fonts">S NO</th>
                  <th className="common-fonts">Tournament Id</th>
                  <th className="common-fonts">Name</th>
                  <th className="common-fonts">Date</th>
                  <th className="common-fonts">Phone</th>
                  <th className="common-fonts">Photos Uploaded</th>
                  <th className="common-fonts">Url</th>
                </tr>
              </thead>
              <tbody>
                {tournaments.length === 0 ? (
                  <tr>
                    <td colSpan={10} style={{ textAlign: "center" }}>
                      {isLoading ? "Loading..." : "No data found"}
                    </td>
                  </tr>
                ) : (
                  tournaments.map((tournament, index) => (
                    <tr key={tournament.id}>
                      <td className="common-fonts">
                        {index + 1 + (currentPage2 - 1) * limit}
                      </td>
                      <td className="common-fonts">{tournament.id}</td>
                      <td className="common-fonts text-capitalize">{tournament.name}</td>
                      <td className="common-fonts">
                        {new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          timeZone: "Asia/Kolkata",
                        }).format(new Date(tournament.creation_date))}
                      </td>
                      <td className="common-fonts">{tournament.phone}</td>
                      <td className="common-fonts">
                           {tournament.photos && tournament.photos.length > 0 
                            ? tournament.photos.split(',').length > 0 
                             ? 'Yes' 
                             : 'No' 
                          : 'No'}
                      </td>
                      <td className="common-fonts tournament-url"><a href={tournament.url} target="_blank">{tournament.url}</a></td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="tour-pagination">
            <button
              className={`tour-arrow-btn ${currentPage2 > 1 ? "" : "tour-hidden-arrow"
                }`}
              onClick={() => handlePageChange2(currentPage2 - 1)}
              disabled={currentPage2 <= 1}
            >
              &#60;
            </button>
            <ul className="tour-pagination-flex">
              {getPaginationNumbers(totalPages2, currentPage2).map((page) => (
                <li key={page}>
                  <button
                    className={`tour-page-btn ${currentPage2 === page ? "active" : ""
                      }`}
                    onClick={() => handlePageChange2(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}
            </ul>
            <button
              className={`tour-arrow-btn ${currentPage2 < totalPages2 ? "" : "tour-hidden-arrow"
                }`}
              onClick={() => handlePageChange2(currentPage2 + 1)}
              disabled={currentPage2 >= totalPages2}
            >
              &#62;
            </button>
          </div>

        </div>
      )}

{isModalOpen && <Modal id={userId} onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default Tournaments;
