import axios from "axios";
import React, { useState } from "react";
import { GET_LOCID_PAGES, getDecryptedToken } from "../utils/Constants";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Trash from "../../assets/image/red-bin.svg";
const MotionModalContent = motion(ModalContent);

function ManagePages() {
  const [searchTerm, setSearchTerm] = useState("");
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState({
    id: null,
    locality: null,
    city: null,
    state: null,
    postcode: null,
    url: null,
  });
  const [images, setImages] = useState([]);

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const updatedImages = [...images];

    for (let i = 0; i < files.length; i++) {
      updatedImages.push(URL.createObjectURL(files[i]));
    }
    setImages(updatedImages);
  };

  const handleSearchLocation = async (e) => {
    e.preventDefault();
    const term = e.target.value;
    setSearchTerm(term);
    if (searchTerm.length < 3) {
      return;
    }

    try {
      const response = await axios.post(
        GET_LOCID_PAGES,
        { term },
        {
          headers: { Authorization: `Bearer ${getDecryptedToken()}` },
        }
      );

      if (response.data.status === 1) {
        setLocations(response.data.data);
      } else {
        setLocations([]);
      }
    } catch (error) {
      setLocations([]);
    }
  };

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  return (
    <div style={{ padding: "20px" }}>
      {/* Search Input */}
      <input
        type="text"
        placeholder="Enter locality, city, state, or postcode"
        value={searchTerm}
        onChange={handleSearchLocation}
        style={{
          padding: "10px",
          marginBottom: "20px",
          width: "100%",
          maxWidth: "400px",
        }}
      />

      {/* Display Results in Table */}
      <table
        border="1"
        cellPadding="10"
        cellSpacing="0"
        style={{ width: "100%", borderCollapse: "collapse" }}
      >
        <thead>
          <tr>
            <th style={{ padding: "10px" }}>Id</th>
            <th style={{ padding: "10px" }}>Locality Name</th>
            <th style={{ padding: "10px" }}>City</th>
            <th style={{ padding: "10px" }}>State</th>
            <th style={{ padding: "10px" }}>Postcode</th>
            <th style={{ padding: "10px" }}>View</th>
          </tr>
        </thead>
        <tbody>
          {locations.length > 0 ? (
            locations.map((location, index) => (
              <tr key={index}>
                <td style={{ padding: "10px" }}>{location.id}</td>
                <td style={{ padding: "10px" }}>{location.locality_name}</td>
                <td style={{ padding: "10px" }}>{location.city}</td>
                <td style={{ padding: "10px" }}>{location.state}</td>
                <td style={{ padding: "10px" }}>{location.postcode}</td>
                <td style={{ padding: "10px", textAlign: "center" }}>
                  <Button
                    size="sm"
                    colorScheme="gray"
                    onClick={() => {
                      setOverlay(<OverlayOne />);
                      onOpen();
                      setLocation({
                        id: location.id,
                        locality: location.locality_name,
                        city: location.city,
                        state: location.state,
                        postcode: location.postcode,
                        url: location.url,
                      });
                    }}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ padding: "10px", textAlign: "center" }}>
                No matching locations found
              </td>
            </tr>
          )}
        </tbody>
      </table>


      <Box p={5}>
        <Modal onClose={onClose} isOpen={isOpen} motionPreset="none">
          {overlay}
          <ModalOverlay />
          <MotionModalContent
            position="fixed"
            right={0}
            top={0}
            bottom={0}
            height="100%"
            maxWidth="none"
            width="60%"
            margin={0}
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="user-team-setting-btn user-team-font top_manage">
              <Text>Page Info</Text>
              <ModalCloseButton />
            </div>
            <hr />
            <ModalBody>
              <>
                <Text fontSize="sm" fontWeight="bold" color="blue.600" mb="1">
                  Id:
                  <Text as="span" fontWeight="normal" color="black">
                    {" "}
                    {location.id}
                  </Text>
                </Text>

                <Text fontSize="sm" fontWeight="bold" color="blue.600" mb="1">
                  Locality Name:
                  <Text as="span" fontWeight="normal" color="black">
                    {" "}
                    {location.locality}
                  </Text>
                </Text>

                <Text fontSize="sm" fontWeight="bold" color="blue.600" mb="1">
                  City:
                  <Text as="span" fontWeight="normal" color="black">
                    {" "}
                    {location.city}
                  </Text>
                </Text>

                <Text fontSize="sm" fontWeight="bold" color="blue.600" mb="1">
                  State:
                  <Text as="span" fontWeight="normal" color="black">
                    {" "}
                    {location.state}
                  </Text>
                </Text>

                <Text fontSize="sm" fontWeight="bold" color="blue.600" mb="1">
                  Postcode:
                  <Text as="span" fontWeight="normal" color="black">
                    {" "}
                    {location.postcode}
                  </Text>
                </Text>

                <Text fontSize="sm" fontWeight="bold" color="blue.600" mb="1">
                  URL:
                  <Text as="span" fontWeight="normal" color="black">
                    <a
                      href="{location.url}"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {location.url}
                    </a>
                  </Text>
                </Text>
                <hr />
              </>

              <br />

              {/* image processinf start */}
              <div>
                <input
                  id="imageUpload"
                  type="file"
                  multiple
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />

                <div className="image-preview-container">

                  <div className={`outerBox2 divWidth`}>
                    {images.map((image, index) => (
                      <div className="bmp-new-img bmp-new-img-2" key={index}>
                        <div className="bmp-img-top-icon">
                          <div className="bmp-img-name">

                            <div className="bmp-video">
                              <a href="" target="_blank" rel="noopener noreferrer">
                                <img
                                  src={image}
                                  alt=""
                                />
                              </a>
                            </div>

                            <p className="common-fonts bmp-tour">
                              Image Name
                            </p>
                          </div>
                          <div className="bmp-trash">
                            <img
                              src={Trash}
                              alt=""
                            />
                          </div>
                        </div>
                        <a href="" target="_blank" rel="noopener noreferrer">
                          <img
                            src={image}
                            alt=""
                            key={index}
                          />
                        </a>
                      </div>
                    ))}
                  </div>

                </div>
                <label htmlFor="imageUpload">
                  <div className="upload-box common-fonts upload-file-name-2 contact-browse-btn-2">
                    <p>You can upload multiple videos and images</p>
                    <p className="browse_btn">Browse</p>
                  </div>
                </label>
              </div>
              {/* image processinf end */}

            </ModalBody>

            <ModalFooter>
              <Button colorScheme="red" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </MotionModalContent>
        </Modal>
      </Box>


    </div>
  );
}

export default ManagePages;
