import axios from 'axios';
import { getDecryptedToken } from '../components/utils/Constants';

// Create the axios instance
const axiosInstance = axios.create({
    baseURL: "https://crm.leadplaner.com/api/api",
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = getDecryptedToken()
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error) 
);


export const apiGetTournamentsByUser = async (body) => {
    try {
        const response = await axiosInstance.post('/bmp/tournament/get-by-user',body)
        return response.data;
    }
    catch (error) {
        return {
            status: 0,
            message: "Internal Server Error",
        };
    }
}


export const apiGetTournamentsByList = async (body) => {
    try {
        const response = await axiosInstance.post('/bmp/tournaments/get',body)
        return response.data;
    }
    catch (error) {
        return {
            status: 0,
            message: "Internal Server Error",
        };
    }
}
export const apiGetUserTournament = async (body) => {
    try {
        const response = await axiosInstance.post('/bmp/tournaments/get-all-by-user ',body)
        return response.data;
    }
    catch (error) {
        return {
            status: 0,
            message: "Internal Server Error",
        };
    }
}