import React from 'react'
import AddNewMailer from './AddNewMailer'
import Mailer from './Mailer'

const MailerTab = () => {
  return (
    <>
    <AddNewMailer/>
    <br />
    <Mailer/>
</>
  )
}

export default MailerTab