import React, { useState, useRef, useEffect } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import { toast } from "react-toastify";
import {
  cdnurl,
  GET_ACADEMY,
  UPDATE_ACADEMY,
  config,
  getDecryptedToken,
  UPLOAD_MEDIA,
} from "../utils/Constants";
import Video from "../../assets/image/video.svg";
import Trash from "../../assets/image/red-bin.svg";
import loadingGif from "../../assets/image/loading.gif";

const LeadImage = ({ id }) => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [photoChoose, setPhotoChoose] = useState(null);
  const decryptedToken = getDecryptedToken();
  const [stateBtn, setStateBtn] = useState(0);
  const [photoBtn, setPhotoBtn] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "video/mp4",
    "video/quicktime",
    "video/webm",
    "video/ogg",
  ];

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [selectedBannerFile, setSelectedBannerFile] = useState(null);
  const [bannerName, setBannerName] = useState(null);
  const fileInputRef2 = useRef(null);
  const [alertShown, setAlertShown] = useState(false);
  const [alertVideoShown, setAlertVideoShown] = useState(false);
  const [isUploadingMulti, setIsUploadingMulti] = useState(false);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [fileName2, setFileName2] = useState(null);
  const [academyData, setAcademyData] = useState({});

  const academyDetails = () => {
    const body = {
      academy_id: id,
      type: "temp",
    };
    axios
      .post(GET_ACADEMY, body, {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      })
      .then((response) => {
        if (response?.data?.data && response?.data?.data?.length !== 0) {
          setAcademyData(response?.data?.data[0]);
          if (
            response?.data?.data[0]?.logo !== "" &&
            response?.data?.data[0]?.logo !== null
          ) {
            setFileName(response?.data?.data[0]?.logo);
          }
          setBannerName(response?.data?.data[0]?.banner);
          if (
            response?.data?.data[0]?.photos !== "" &&
            response?.data?.data[0]?.photos !== null
          ) {
            setPhotoUrls(response?.data?.data[0]?.photos?.split(","));
          }
          if (
            response?.data?.data[0].videos !== "" &&
            response?.data?.data[0].videos !== null
          ) {
            setVideoUrls(response?.data?.data[0]?.videos?.split(","));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    academyDetails();
  }, []);

  const processImageName = (imageName) => {
    const nameParts = imageName.split(".");
    if (nameParts?.length > 1) {
      const namePart = nameParts.slice(0, -1).join(".");
      const processedName = namePart.replace(/[^\w-]/g, "-");
      return `${processedName}.${nameParts[nameParts.length - 1]}`;
    } else {
      return imageName.replace(/[^\w-]/g, "-");
    }
  };
  const handleCheckbox = (photo, index) => {
    console.log(photo);
    setPhotoChoose(photo);
    setSelectedPhoto(index);
    setPhotoBtn(1);
    setSelectedPhotoIndex(null);
  };
  const handleCheckboxChange = (video, index) => {
    setBannerName(video);
    setSelectedPhotoIndex(index);
    setStateBtn(1);
    setSelectedPhoto(null);
  };

  //=================================================================================photo and video upload

  const handleButtonClick2 = () => {
    fileInputRef2.current.click();
    setAlertVideoShown(false);
    setAlertShown(false);
  };
  const handleFileChange2 = (event) => {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // if (!allowedFileTypes.includes(file.type)) {
      //     if (!alertShown) {
      //         alert("Please choose a valid video or image file.");
      //         setAlertShown(true);
      //     }
      //     return;
      // }
      if (file.type.startsWith("video/") && file.size > 120 * 1024 * 1024) {
        alert("Video file size should be less than 120 MB.");
        return;
      }
      if (file.type.startsWith("image/")) {
        submitImage2(file);
      } else if (file.type.startsWith("video/")) {
        submitVideo2(file);
      }
    }
  };
  const submitImage2 = (file) => {
    setIsUploadingMulti(true);
    const selectedImage = file;

    if (selectedImage) {
      const processedFileName = processImageName(selectedImage.name);
      const modifiedFile = new File([selectedImage], processedFileName, {
        type: selectedImage.type,
      });
      AWS.config.update({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        region: config.region,
      });

      const s3 = new AWS.S3();

      const uploadPromises = [
        {
          bucketName: config.bucketName,
          dirName: "academy_temp/" + (id || ""),
        },
        { bucketName: "destcdn90", dirName: "academy_temp/" + (id || "") },
      ].map((updatedConfig) => {
        const params = {
          Bucket: updatedConfig.bucketName,
          Key: updatedConfig.dirName + "/" + modifiedFile.name,
          Body: modifiedFile,
        };

        return s3
          .upload(params)
          .promise()
          .then((data) => {
            const imageUrl = modifiedFile.name;
            if (data.Location) {
              return { success: true, imageUrl, updatedConfig };
            }
          })
          .catch((err) => {
            return { success: false, error: err, updatedConfig };
          });
      });

      let successfulUploadCount = 0;

      Promise.all(uploadPromises)
        .then((results) => {
          results.forEach((result) => {
            if (result.success) {
              const { imageUrl, updatedConfig } = result;
              if (updatedConfig.bucketName === config.bucketName) {
                successfulUploadCount++;
                photoUrls.push(imageUrl);
              } else if (updatedConfig.bucketName === "destcdn90") {
                successfulUploadCount++;
              }
            } else {
              console.error("Failed to upload:", result.error);
            }
          });

          if (successfulUploadCount === 2) {
            setPhotoUrls([...photoUrls]);
            handleSubmit2();
          } else {
            console.error("Uploads to both buckets were not successful.");
          }
        })
        .catch((err) => {
          console.error("Error uploading to multiple buckets:", err);
        })
        .finally(() => {
          setIsUploadingMulti(false);
        });
    }
  };

  const submitVideo2 = (file) => {
    setIsUploadingMulti(true);
    const selectedVideo = file;

    if (selectedVideo) {
      const processedFileName = processImageName(selectedVideo.name);
      const modifiedFile = new File([selectedVideo], processedFileName, {
        type: selectedVideo.type,
      });
      AWS.config.update({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        region: config.region,
      });

      const s3 = new AWS.S3();

      const uploadPromises = [
        {
          bucketName: config.bucketName,
          dirName: "academy_temp/" + (id || ""),
        },
        { bucketName: "destcdn90", dirName: "academy_temp/" + (id || "") },
      ].map((updatedConfig) => {
        const params = {
          Bucket: updatedConfig.bucketName,
          Key: updatedConfig.dirName + "/" + modifiedFile.name,
          Body: modifiedFile,
        };

        return s3
          .upload(params)
          .promise()
          .then((data) => {
            const videoUrl = modifiedFile.name;
            if (data.Location) {
              return { success: true, videoUrl, updatedConfig };
            }
          })
          .catch((err) => {
            return { success: false, error: err, updatedConfig };
          });
      });

      let successfulUploadCount = 0;

      Promise.all(uploadPromises)
        .then((results) => {
          results.forEach((result) => {
            if (result.success) {
              const { videoUrl, updatedConfig } = result;
              if (updatedConfig.bucketName === config.bucketName) {
                successfulUploadCount++;
                videoUrls.push(videoUrl);
              } else if (updatedConfig.bucketName === "destcdn90") {
                successfulUploadCount++;
              }
            } else {
              console.error("Failed to upload:", result.error);
            }
          });

          if (successfulUploadCount === 2) {
            setVideoUrls([...videoUrls]);
            handleSubmit2();
          } else {
            console.error("Uploads to both buckets were not successful.");
          }
        })
        .catch((err) => {
          console.error("Error uploading to multiple buckets:", err);
        })
        .finally(() => {
          setIsUploadingMulti(false);
        });
    }
  };

  //===============================================================================image submit
  const initialPhotoUrls = [...photoUrls];
  const initialVideoUrls = [...videoUrls];
  const initialFileName = fileName;
  const initialFileName2 = fileName2;
  const initialSelectedFile = selectedFile;

  const resetState = () => {
    setPhotoUrls(initialPhotoUrls);
    setVideoUrls(initialVideoUrls);
    setFileName(initialFileName);
    setFileName2(initialFileName2);
    setSelectedFile(initialSelectedFile);
  };
  const handleSubmit = (logoValue, bannerValue) => {
    setPhotoBtn(0);
    const updatedFormData = {
      type: "temp",
      logo: logoValue,
      banner: bannerValue,
      photos: photoUrls?.join(","),
      videos: videoUrls?.join(","),
      name: academyData?.name,
      sport_id: academyData?.sport_id ?? 14,
      loc_id: academyData?.loc_id,
    };
    axios
      .put(UPDATE_ACADEMY + id, updatedFormData, {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Details updated successfully", {
            position: "top-center",
            autoClose: 1000,
          });
        } else {
          toast.error(response?.data?.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
        academyDetails();
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred while updating details", {
          position: "top-center",
          autoClose: 1000,
        });
      })
      .finally(() => {
        setPhotoBtn(0);
        setStateBtn(0);
      });
  };

  const handleSubmitlogo = () => {
    handleSubmit(photoChoose, bannerName);
  };

  const handleSubmitbanner = () => {
    handleSubmit(fileName, photoChoose);
  };

  const handleSubmit2 = () => {
    setStateBtn(0);
    const updatedFormData = {
      type: "temp",
      logo: fileName,
      banner: bannerName,
      photos: photoUrls?.join(","),
      videos: videoUrls?.join(","),
      name: academyData?.name,
      sport_id: academyData?.sport_id ?? 14,
      loc_id: academyData?.loc_id,
    };
    axios
      .put(UPDATE_ACADEMY + id, updatedFormData, {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Details updated successfully", {
            position: "top-center",
            autoClose: 1000,
          });
        } else {
          toast.error(response?.data?.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
        academyDetails();
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred while updating details", {
          position: "top-center",
          autoClose: 1000,
        });
      })
      .finally(() => {
        setStateBtn(0);
      });
  };
  // ==================================================================================delete the phots and videos

  const deleteStrategy = (photoToDelete) => {
    const updatedNameOfStrategy = photoUrls.filter(
      (photo) => photo !== photoToDelete
    );
    setPhotoUrls(updatedNameOfStrategy);
    updateDataAndCallAPI(updatedNameOfStrategy);
  };
  const updateDataAndCallAPI = (updatedNameArray) => {
    const updatedNameString = updatedNameArray.join(",");
    axios
      .put(
        UPDATE_ACADEMY + academyData?.id,
        {
          type: "temp",
          photos: updatedNameString,
          name: academyData?.name,
          sport_id: academyData?.sport_id,
          loc_id: academyData?.loc_id,
        },
        {
          headers: {
            Authorization: `Bearer ${decryptedToken}`,
          },
        }
      )
      .then((response) => {
        academyDetails();
      })
      .catch((error) => {
        console.error("API call failed:", error);
      });
  };

  const deleteVideo = (videoToDelete) => {
    const updatedNameOfStrategy = videoUrls.filter(
      (photo) => photo !== videoToDelete
    );
    setVideoUrls(updatedNameOfStrategy);
    updateData(updatedNameOfStrategy);
  };
  const updateData = (updatedNameArray) => {
    const updatedNameString = updatedNameArray.join(",");
    axios
      .put(
        UPDATE_ACADEMY + academyData?.id,
        {
          type: "temp",
          videos: updatedNameString,
          name: academyData?.name,
          sport_id: academyData?.sport_id,
          loc_id: academyData?.loc_id,
        },
        {
          headers: {
            Authorization: `Bearer ${decryptedToken}`,
          },
        }
      )
      .then((response) => {
        academyDetails();
      })
      .catch((error) => {
        console.error("API call failed:", error);
      });
  };

  ////////////Taher code///////////////

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 0) {
      setIsLoading(true);
      for (const file of selectedFiles) {
        const mediaType = file.type.startsWith("image/") ? "image" : "video";

        // Check for video file size
        if (mediaType === "video" && file.size > 120 * 1024 * 1024) {
          toast.error("Video size should be less than 120 Mb", {
            position: "top-center",
            autoClose: 1000,
          });
          continue;
        }

        await uploadFile(file);
      }
      academyDetails();
      setIsLoading(false);
    }
  };

  const uploadFile = async (file) => {
    const mediaType = file.type.startsWith("image/") ? "image" : "video";

    const formData = new FormData();
    formData.append("academyId", id);
    formData.append("type", "temp");
    formData.append("file", file);
    formData.append("media_type", mediaType);

    try {
      const response = await axios.post(UPLOAD_MEDIA, formData, {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status === true) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      } else {
        toast.error(response.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(`Failed to upload file: ${file.name}`, {
        position: "top-center",
        autoClose: 1000,
      });
      console.error(`Error uploading file ${file.name}:`, error);
    }
  };

  return (
    <>
      {/* ================================================================================upload the logo */}
      <section className="img_upload_newflex">
        <p className="common-fonts">Academy Logo : </p>
        <span className="common-fonts">
          {fileName ? fileName : academyData?.logo}
        </span>
        <div className="bmp-upload">
          {!selectedFile && (
            <div className="bmp-image-preview">
              <a
                href={
                  academyData?.logo === null
                    ? `${cdnurl}asset/images/logo.svg`
                    : `${cdnurl}academy_temp/${academyData?.id}/${academyData?.logo}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    academyData?.logo === null || academyData?.logo === ""
                      ? `${cdnurl}asset/images/logo.svg`
                      : `${cdnurl}academy_temp/${academyData?.id}/${academyData?.logo}`
                  }
                  alt=""
                  className="bmp-preview-image"
                />
              </a>
            </div>
          )}
        </div>
      </section>
      {/* =============================================================================upload banner */}

      <section className="img_upload_newflex">
        <p className="common-fonts">Academy banner image :</p>
        <span className="common-fonts">
          {bannerName ? bannerName : academyData?.banner}
        </span>
        <div className="bmp-upload">
          {!selectedBannerFile && (
            <div className="bmp-image-preview">
              <a
                href={
                  academyData?.banner === null
                    ? `${cdnurl}default/${academyData?.sport}_banner.webp`
                    : `${cdnurl}academy_temp/${academyData?.id}/${academyData?.banner}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    academyData?.banner === null
                      ? `${cdnurl}default/${academyData?.sport}_banner.webp`
                      : `${cdnurl}academy_temp/${academyData?.id}/${academyData?.banner}`
                  }
                  alt=""
                  className="bmp-preview-image"
                />
              </a>
            </div>
          )}
        </div>
      </section>

      {/* =========================================================multiple photo and video upload */}
      <section>
        <p className="common-fonts">Upload images/videos</p>
        <div className="bmp-upload new-bmp-upload">
          <div className="contact-browse-new deal-doc-file">
            {/* <span
                            className="common-fonts common-input contact-tab-input"
                            style={{
                                position: "relative",
                                marginRight: "10px",
                            }}
                        >
                            <button
                                className={`common-fonts contact-browse-btn `}
                                onClick={handleButtonClick2}>
                                Browse
                            </button>
                            <input
                                type="file"
                                style={{
                                    display: "none",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    width: "100%",
                                }}
                                ref={fileInputRef2}
                                onChange={handleFileChange2}
                                multiple
                            />
                            {isUploadingMulti ? (
                                <span className="common-fonts upload-file-name">
                                    Uploading...
                                </span>
                            ) : (
                                <span className="common-fonts upload-file-name">
                                    <p className="common-fonts light-color">
                                        You can upload multiple videos and images{" "}
                                    </p>
                                </span>
                            )}
                        </span> */}

            <div className="upload-container">
              <label className="upload-box">
                <span className="upload-message">
                  You can upload multiple videos and images
                </span>
                <input
                  type="file"
                  accept="image/*,video/*"
                  multiple
                  className="hidden-input"
                  onChange={handleFileChange}
                />
                <span className="browse-button">Browse</span>
              </label>
            </div>
            <div className="photo-video">
              Photos <span>({photoUrls?.length ?? 0})</span>
              Videos <span>({videoUrls?.length ?? 0})</span>
            </div>
          </div>
        </div>
      </section>
      {/* ====================================================map for admin photos */}
      <>
        {isLoading && (
          <div className="loading-container">
            <img src={loadingGif} alt="Loading..." className="loading-gif" />
          </div>
        )}
        {photoUrls?.length === 0 ? (
          <div className={`support-no-ticket-found`}>
            <p className="common-fonts">No photos added</p>
          </div>
        ) : (
          <div className={`outerBox divWidth`}>
            {photoUrls?.map((photo, index) => (
              <div className="bmp-new-img" key={index}>
                <div className="bmp-img-top-icon">
                  <div className="bmp-img-name">
                    <input
                      type="checkbox"
                      className="radio_disable check_input"
                      checked={selectedPhoto === index}
                      onChange={() => handleCheckbox(photo, index)}
                    />
                    <div className="bmp-video">
                      <a
                        href={`${cdnurl}academy_temp/${academyData?.id}/${photo}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`${cdnurl}academy_temp/${academyData?.id}/${photo}`}
                          alt=""
                        />
                      </a>
                    </div>

                    <p className="common-fonts bmp-tour">
                      {photo?.length > 20 ? (
                        <>{photo?.slice(0, 20)}...</>
                      ) : (
                        <>{photo}</>
                      )}
                    </p>
                  </div>
                  <div className="bmp-trash">
                    <img
                      src={Trash}
                      alt=""
                      onClick={() => deleteStrategy(photo)}
                    />
                  </div>
                </div>
                <a
                  href={`${cdnurl}academy_temp/${academyData?.id}/${photo}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${cdnurl}academy_temp/${academyData?.id}/${photo}`}
                    alt=""
                    key={index}
                  />
                </a>
              </div>
            ))}
          </div>
        )}
      </>
      <>
        {videoUrls?.length === 0 ? (
          <div className="support-no-ticket-found">
            <p className="common-fonts">No videos added</p>
          </div>
        ) : (
          <div className="outerBox">
            {videoUrls?.map((video, index) => (
              <div className="bmp-new-img" key={index}>
                <div className="bmp-img-top-icon">
                  <div className="bmp-img-name">
                    <input
                      type="checkbox"
                      className="radio_disable check_input"
                      checked={selectedPhotoIndex === index}
                      onChange={() => handleCheckboxChange(video, index)}
                    />
                    <div className="bmp-video">
                      <img src={Video} alt="" />
                    </div>
                    <p className="common-fonts bmp-tour">
                      {video?.length > 20 ? (
                        <>{video?.slice(0, 20) + "..."}</>
                      ) : (
                        <>{video}</>
                      )}
                    </p>
                  </div>
                  <div className="bmp-trash">
                    <img
                      src={Trash}
                      alt=""
                      onClick={() => deleteVideo(video)}
                    />
                  </div>
                </div>
                <div className="bmp-player-img">
                  <a
                    href={`${cdnurl}academy_temp/${academyData?.id}/${video}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <video width="270" height="140" controls>
                      <source
                        src={`${cdnurl}academy_temp/${academyData?.id}/${video}`}
                        type="video/mp4"
                      />
                    </video>
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </>

      <div className="bmp-bottom-btn">
        <button
          className="common-fonts common-white-button"
          onClick={resetState}
        >
          Cancel
        </button>
        {photoBtn === 0 ? (
          <>
            <button className="disabledBtn" disabled>
              Select your logo
            </button>
            <button className="disabledBtn" disabled>
              Save as Banner
            </button>
          </>
        ) : (
          <>
            <button
              className="common-fonts common-save-button"
              onClick={handleSubmitlogo}
            >
              Select your logo
            </button>
            <button
              className="common-fonts common-save-button"
              onClick={handleSubmitbanner}
            >
              Select your Banner
            </button>
          </>
        )}
        {/* {stateBtn === 0 ? (
                    <button className="disabledBtn" disabled>
                        Save
                    </button>
                ) : (
                    <button
                        className="common-fonts common-save-button"
                        onClick={handleSubmit2}
                    >
                        Save
                    </button>
                )} */}
      </div>
    </>
  );
};

export default LeadImage;
